import * as types from "../types/dialog_type";

const initialState = {
  //For dialog
  showDialog: false,
  dialogTitle: "",
  dialogDescription: "",
  dialogInfo: {
    visible: false,
    title: "",
    message: "",
    video: "",
    message2: "",
    dialogOrigin: "",
  },
  dialogInfoActivelyAvailable: false,
  dialogConfirm: {
    visible: false,
    title: "",
    message: "",
  },
  showNoCvDialog: false,
  showJobAppliedDialog: false,
  showJobAlertDialog: false,
  showJobAlertNotificationsDialog: false,
  showJobAlertDeleteDialog: false,
  showJobAlertDownloadAppDialog: false,
  showJobAlertVisitorEmailDialog: false,
  showJobAlertVisitorUnsubscribeDialog: false,
  showJobAlertCreateDialog: false,
  // Visitor Email Modal in Job Details Page
  showJobDetailsVisitorEmailDialog: false,
  visitorEmailDialogInfo: {
    email: "",
    keyword: "",
  },
  logInJobAlertDialogInfo: {
    keyword: "",
  },
  jobAlertKeywordOrigin: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For dialog
    case types.SHOW_DIALOG: {
      return {
        ...state,
        showDialog: true,
        dialogTitle: action.dialogTitle,
        dialogDescription: action.dialogDescription,
      };
    }
    case types.HIDE_DIALOG: {
      return {
        ...state,
        showDialog: false,
        dialogTitle: "",
        dialogDescription: "",
      };
    }
    case types.SHOW_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onOkClicked: action.payload?.onOkClicked,
          video: action.payload?.video,
          message2: action.payload?.message2,
          dialogOrigin: action.payload?.dialogOrigin,
        },
      };
    }
    case types.SHOW_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onOkClicked: action.payload?.onOkClicked,
          video: action.payload?.video,
          message2: action.payload?.message2,
        },
      };
    }
    case types.SHOW_INFO_DIALOG_ACTIVELY_AVAILABLE: {
      return {
        ...state,
        dialogInfoActivelyAvailable: action.payload,
      };
    }
    case types.CLEAR_INFO_CONTENT: {
      return {
        ...state,
        dialogInfo: {
          visible: false,
          title: "",
          message: "",
          dialogOrigin: "",
        },
      };
    }
    case types.HIDE_INFO_DIALOG: {
      return {
        ...state,
        dialogInfo: {
          ...state.infoDialog,
          visible: false,
        },
      };
    }
    case types.SHOW_CONFIRMATION_DIALOG: {
      return {
        ...state,
        dialogConfirm: {
          visible: true,
          title: action.payload?.title,
          message: action.payload?.message,
          onConfirmClicked: action.payload?.onConfirmClicked,
          onCancelClicked: action.payload?.onCancelClicked,
        },
      };
    }
    case types.CLEAR_CONFIRMATION_CONTENT: {
      return {
        ...state,
        dialogConfirm: {
          visible: false,
          title: "",
          message: "",
        },
      };
    }
    case types.HIDE_CONFIRMATION_DIALOG: {
      return {
        ...state,
        dialogConfirm: {
          ...state.confirmationDialog,
          visible: false,
        },
      };
    }
    case types.UPDATE_NO_CV_DIALOG_STATUS: {
      return {
        ...state,
        showNoCvDialog: action.status,
      };
    }
    case types.UPDATE_JOB_APPLIED_DIALOG: {
      return {
        ...state,
        showJobAppliedDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DIALOG: {
      return {
        ...state,
        showJobAlertDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_NOTIFICATIONS_DIALOG: {
      return {
        ...state,
        showJobAlertNotificationsDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DELETE_DIALOG: {
      return {
        ...state,
        showJobAlertDeleteDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_DOWNLOAD_APP_DIALOG: {
      return {
        ...state,
        showJobAlertDownloadAppDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_VISITOR_EMAIL_DIALOG: {
      return {
        ...state,
        showJobAlertVisitorEmailDialog: action.status,
      };
    }
    case types.UPDATE_JOB_ALERT_VISITOR_UNSUBSCRIBE_DIALOG: {
      return {
        ...state,
        showJobAlertVisitorUnsubscribeDialog: action.status,
      };
    }

    case types.UPDATE_JOB_ALERT_CREATE_DIALOG: {
      return {
        ...state,
        showJobAlertCreateDialog: action.status,
      };
    }

    case types.UPDATE_JOB_ALERT_CREATE_DIALOG_KEYWORD: {
      return {
        ...state,
        logInJobAlertDialogInfo: {
          keyword: action.keyword ?? "",
        },
        jobAlertKeywordOrigin:
          action.jobAlertKeywordOrigin ?? state.jobAlertKeywordOrigin,
      };
    }

    case types.UPDATE_JOB_DETAILS_VISITOR_JOB_ALERT_DIALOG: {
      return {
        ...state,
        showJobDetailsVisitorEmailDialog: action.status,
      };
    }

    case types.UPDATE_VISITOR_JOB_ALERT_DIALOG_DETAILS: {
      return {
        ...state,
        visitorEmailDialogInfo: {
          email: action.payload.email ?? state.visitorEmailDialogInfo.email,
          keyword:
            action.payload.keyword ?? state.visitorEmailDialogInfo.keyword,
        },
      };
    }

    case types.UPDATE_VISITOR_JOB_ALERT_DIALOG_ORIGIN: {
      return {
        ...state,
        jobAlertKeywordOrigin: action.origin,
      };
    }

    default: {
      return state;
    }
  }
};
