import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Cookies from "js-cookie";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { Fragment, useEffect } from "react";
import { FacebookProvider } from "react-facebook";
import { useDispatch } from "react-redux";
import theme from "../assets/theme";
import * as config from "../config/config";
import { getItem, removeItem } from "../helpers/data_management";
import { sendTrackingEvent } from "../helpers/tracking_management";
import { getUser } from "../redux/actions/user_action";
import { wrapper } from "../redux/stores/store";
import * as types from "../redux/types/user_type";

let cleverTapInitRetryCount = 0;

function MyApp(props) {
  const { Component, pageProps, err } = props;
  const user = getUser();
  const router = useRouter();
  const dispatch = useDispatch();

  // // Show sign up dialog when specific params is present in URL
  // if (router.query.signup === "false" && !checkAuthentication()) {
  //   dispatch(updateShowSignInModalStatus(true));
  //   dispatch(updateSignInModalSignUpStatus(true));
  // }

  const triggerCleveTapWebPushNotifications = () => {
    // setTimeout(() => {
    try {
      // Grant permission from users to subscribe to CleverTap's web push notifications
      clevertap.notifications.push({
        // apnsWebPushId: "<apple web push id>", // Only for safari browser
        // apnsWebPushServiceUrl: "<safari package service url>", // Only for safari browser
        titleText: "Would you like to receive Push Notifications?",
        bodyText:
          "We promise to only send you relevant content and give you updates on your transactions",
        okButtonText: "Sign me up!",
        rejectButtonText: "No thanks",
        okButtonColor: "#512acc",
        serviceWorkerPath: "./clevertap_sw.js", // path to your custom service worker file
      });
    } catch (error) {
      console.error(error);
    }
    // }, 1000);
  };

  const triggerCleverTap = () => {
    setTimeout(() => {
      try {
        clevertap.onUserLogin.push({
          Site: {
            Name: user.name ?? "-", // String
            Identity: user.id ?? "-", // String or number
            Email: user.email ?? "-", // Email address of the user
            Phone: user.mobileNumber ?? "-", // Phone (with the country code)
            Gender: user.gender === "male" ? "M" : "F", // Can be either M or F
            DOB: user.birthDate ? new Date(user.birthDate) : "-", // Date of Birth. Date object
          },
        });
      } catch (error) {
        console.error("clevertap_error:", error);

        if (error && cleverTapInitRetryCount < 6) {
          cleverTapInitRetryCount++;
          triggerCleverTap();
        }
      }
    }, 1000);
  };

  useEffect(() => {
    // Trigger CleverTap event upon change on user properties
    const clevertap_init = getItem(types.CLEVERTAP_INIT);

    if (user?.id && clevertap_init) {
      removeItem(types.CLEVERTAP_INIT); // Remove CleverTap initialization status once the user logs in
      triggerCleverTap();
      triggerCleveTapWebPushNotifications();
    }
  }, [user]);

  useEffect(() => {
    // if user already logged-in, store user ID to cookies to be used by GA with 1 year expiry
    const user = getUser();
    if (user?.id) {
      Cookies.set("userId", user?.id, { expires: 31540000 });
      sendTrackingEvent({
        event: "CE_auto-login",
      });
    }

    Cookies.set("isRegistered", user?.id ? true : false, { expires: 365 });

    // Check UTM params when page initially loads to store into cookies for ads sign up tracking
    const params = new URL(location.href).searchParams;
    const utm_medium = params.get("utm_medium");
    const URLparams = window?.location.search.substring(1);

    if (utm_medium?.length > 0) {
      Cookies.set("utm_params", URLparams, { expires: 7 });
    }
  }, []);

  return (
    <Fragment>
      <Head>
        {/* Favicon */}
        <link
          rel="icon"
          href={config.assetDomain + "/images/logo/naikgaji-favicon.png"}
        />

        {/* GTM tracking script */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NCZF2HF');`,
          }}
        ></script> */}

        {/* CleverTap */}
        {user?.id && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `var clevertap = {event:[], profile:[], account:[], onUserLogin:[], region:'sg1', notifications:[], privacy:[]};
                clevertap.account.push({"id": "${process?.env?.NEXT_PUBLIC_CLEVERTAP}"});
                clevertap.privacy.push({optOut: false});
                clevertap.privacy.push({useIP: false}); 
                (function () {
                var wzrk = document.createElement('script');
                wzrk.type = 'text/javascript';
                wzrk.async = true;
                wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/clevertap.min.js';        
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(wzrk, s);
                })();`,
            }}
          ></script>
        )}
      </Head>

      <ThemeProvider theme={theme}>
        <FacebookProvider appId={process.env.FACEBOOK_CLIENT_ID}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} err={err ? err : {}} />
          </LocalizationProvider>
        </FacebookProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default wrapper.withRedux(MyApp);
