import * as types from "../types/user_type";

const initialState = {
  user: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        user: { ...action.payload.user },
      };
    }
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        user: { ...action.payload.user },
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        user: {},
      };
    }
    case types.UPLOAD_RESUME_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          resume: action?.payload?.user?.resume,
          approved: action?.payload?.user?.approved,
          state: action?.payload?.user?.state,
        },
      };
    }
    case types.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: { ...action?.payload?.user },
      };
    }
    case types.SAVE_EDUCATION_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          educations: action?.payload?.latestEducations,
        },
      };
    }
    case types.SAVE_BASIC_INFO_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          name: action?.payload?.latestUser?.name,
          gender: action?.payload?.latestUser?.gender,
          birthDate: action?.payload?.latestUser?.birthDate,
          nationality: action?.payload?.latestUser?.nationality,
          mobileNumber: action?.payload?.latestUser?.mobileNumber,
          currentCompany: action?.payload?.latestUser?.currentCompany,
          shortSummary: action?.payload?.latestUser?.shortSummary,
          onBoardingV3Complete:
            action?.payload?.latestUser?.onBoardingV3Complete,
          major: action?.payload?.latestUser?.major,
        },
      };
    }
    case types.SAVE_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profileImageSquare: action?.payload?.latestUser?.profileImageSquare,
        },
      };
    }
    default:
      return state;
  }
};
