import * as types from "../types/job_type";

const initialState = {
  // For Job List
  fetchingAllJobs: false,
  loadJobKey: "",
  jobs: [],
  jobsParams: {
    expectedSalary: 0,
    type: "",
    expectedSalaryFull: "",
    expectedSalaryFullBm: "",
    keyword: "*",
    keywordDisplay: "",
    trackIds: [],
    trackIdsFull: [],
    stateRegions: [],
    stateRegionsFull: [],
    jobTypeIds: [],
    jobTypeIdsFull: [],
    experienceIds: [],
    experienceIdsFull: [],
  },
  pagination: {
    first: null,
    last: null,
    startCursor: null,
    endCursor: null,
  },
  filterApplied: false,
  jobFilters: [],
  jobPageInfo: {},
  jobCurrentPage: 1,
  error: false,
  errorMessage: "",
  currentLoadingJobsKey: "",
  // For Homepage Trending Jobs
  trendingJobs: [],
  fetchingTrendingJobs: false,
  // For Single Job Page
  job: {},
  selectedId: "",
  fetchingJob: false,
  fetchingJobError: false,
  // For Job Company Jobs
  jobCompanyJobs: [],
  fetchingJobCompanyJob: false,
  // For Filters Suggestion
  tracks: [],
  stateRegions: [],
  jobTypes: [],
  salaryTypes: [],
  experienceLevels: [],
  appliedJobs: [],
  pendingJobs: [],
  // For Homepage Banners
  homepageBanners: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For Job List
    case types.CLEAR_FILTERS: {
      return {
        ...state,
        jobCurrentPage: 1,
      };
    }

    case types.UPDATE_JOB_PARAMS: {
      return {
        ...state,
        jobsParams: action.payload,
      };
    }

    case types.UPDATE_APPLIED_FILTER: {
      return {
        ...state,
        filterApplied: action.payload,
      };
    }

    case types.FETCHING_ALL_JOBS: {
      return {
        ...state,
        fetchingAllJobs: action.payload,
      };
    }

    case types.UPDATE_LOAD_JOB_KEY: {
      return {
        ...state,
        loadJobKey: action.payload,
      };
    }

    case types.FETCH_JOBS_SUCCEED: {
      const jobList = action.payload.jobList;

      let currentPage = state.jobCurrentPage;

      if (action.payload.loadJobKey !== state.loadJobKey) {
        return { ...state };
      }

      return {
        ...state,
        jobs: jobList,
        totalJobsPages: action.payload.totalJobsPages,
        totalJobsCount: action.payload.totalJobsCount,
        jobPageInfo: action.payload.pageInfo,
        jobCurrentPage: currentPage + action.payload.jobCurrentPage,
        fetchingAllJobs: false,
      };
    }

    case types.FETCH_JOBS_FAILED: {
      //use back existing fetchingJobList status if key does not match
      let cFetchingJobList =
        action.currentLoadingJobsKey === state.currentLoadingJobsKey
          ? false
          : state.fetchingAllJobs;

      return {
        ...state,
        fetchingAllJobs: cFetchingJobList,
        error: true,
        errorMessage: action.errorMessage,
        fetchingTotalJobsPages: false,
        ashleyToggleState: false,
      };
    }

    // For Homepage trending jobs
    case types.GET_TRENDING_JOBS: {
      return {
        ...state,
        fetchingTrendingJobs: true,
      };
    }

    case types.GET_TRENDING_JOBS_SUCCEED: {
      return {
        ...state,
        fetchingTrendingJobs: false,
        trendingJobs: action.payload.trendingJobs,
      };
    }

    case types.GET_TRENDING_JOBS_FAILED: {
      return {
        ...state,
        fetchingTrendingJobs: false,
      };
    }

    // For Job
    case types.GET_JOB: {
      return {
        ...state,
        fetchingJob: true,
        jobCompanyJobs: [], // For now we could reset this as only single job and job list is calling GET_JOB
      };
    }
    case types.GET_JOB_SUCCEED: {
      let cJob = action.jobId == state.job.id ? action.job : state.job;
      return {
        ...state,
        job: cJob,
        selectedId: action.jobId,
        fetchingJob: false,
        fetchingJobError: false,
      };
    }
    case types.GET_JOB_FAILED: {
      let cFetchingJob =
        action.jobId == state.job.id ? false : state.fetchingJob;
      return {
        ...state,
        fetchingJob: cFetchingJob,
        fetchingJobError: false,
      };
    }

    // Apply Job
    case types.APPLY_JOB: {
      return {
        ...state,
        applyingJob: true,
      };
    }

    // For Job Company Jobs
    case types.GET_JOB_COMPANY_JOBS: {
      return {
        ...state,
        fetchingJobCompanyJob: true,
      };
    }
    case types.GET_JOB_COMPANY_JOB_SUCCEED: {
      return {
        ...state,
        jobCompanyJobs: action.payload.companyJobs,
        fetchingJobCompanyJob: false,
      };
    }
    case types.GET_JOB_COMPANY_JOB_FAILED: {
      return {
        ...state,
        jobCompanyJobs: action.payload.companyJobs,
        fetchingJobCompanyJob: false,
      };
    }

    // For Filters Suggestion
    case types.GET_TRACK_SUCCEEDED: {
      return {
        ...state,
        tracks: action.payload,
      };
    }

    case types.GET_STATEREGION_SUCCEEDED: {
      return {
        ...state,
        stateRegions: action.payload,
      };
    }

    case types.GET_JOB_TYPE_SUCCEEDED: {
      return {
        ...state,
        jobTypes: action.payload,
      };
    }

    case types.GET_EXPERIENCE_LEVEL_SUCCEEDED: {
      return {
        ...state,
        experienceLevels: action.payload,
      };
    }

    case types.GET_APPLIED_JOBS_SUCCESS: {
      return {
        ...state,
        appliedJobs: action?.payload?.appliedJobs,
        jobPageInfo: action?.payload?.pageInfo,
      };
    }

    case types.GET_PENDING_JOBS_SUCCESS: {
      return {
        ...state,
        pendingJobs: action?.payload?.pendingJobs,
        jobPageInfo: action?.payload?.pageInfo,
      };
    }

    case types.CANCEL_JOB_APPLICATION_SUCCESS: {
      return {
        ...state,
        pendingJobs: [...state.pendingJobs].filter(
          (job) => job.id !== action?.payload?.cancelledJobId
        ),
      };
    }

    case types.APPLY_JOB_SUCCEED: {
      const updatedJobsArr = state.jobs?.map((job) => {
        return job.id === action?.jobId ? { ...job, haveApplied: true } : job;
      });

      return {
        ...state,
        jobs: updatedJobsArr,
      };
    }

    case types.GET_SALARY_TYPE_SUCCESS: {
      return {
        ...state,
        salaryTypes: action.payload?.salaryTypes,
      };
    }

    // For Homepage Banners
    case types.FETCH_HOMEPAGE_BANNERS: {
      return {
        ...state,
        homepageBanners: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
