// FETCHING JOB LIST
export const FETCHING_JOBS_KEY = "FETCHING_JOBS_KEY";
export const FETCHING_JOBS = "FETCHING_JOBS";
export const FETCH_JOBS_SUCCEED = "FETCH_JOBS_SUCCEED";
export const FETCH_JOBS_FAILED = "FETCH_JOBS_FAILED";
export const CLEAR_JOB_LIST = "CLEAR_JOB_LIST";
export const NO_JOBS_FOUND = "NO_JOBS_FOUND";
export const UPDATE_JOB_ID = "UPDATE_JOB_ID";
export const GET_JOBS_PARAMS = "GET_JOBS_PARAMS";
export const FETCHING_ALL_JOBS = "FETCHING_ALL_JOBS";
export const UPDATE_JOB_PARAMS = "UPDATE_JOB_PARAMS";
export const UPDATE_APPLIED_FILTER = "UPDATE_APPLIED_FILTER";
export const UPDATE_LOAD_JOB_KEY = "UPDATE_LOAD_JOB_KEY";

// FOR JOB SUGGESTION
export const FETCH_JOB_SUGGESTION_KEY = "FETCH_JOB_SUGGESTION_KEY";
export const FETCH_JOB_SUGGESTION = "FETCH_JOB_SUGGESTION";
export const FETCH_JOB_SUGGESTION_SUCCEED = "FETCH_JOB_SUGGESTION_SUCCEED";
export const FETCH_JOB_SUGGESTION_FAILED = "FETCH_JOB_SUGGESTION_FAILED";
export const JOB_SUGGESTION_END = "JOB_SUGGESTION_END";
export const CLEAR_JOB_SUGGESTION = "CLEAR_JOB_SUGGESTION";

// FOR JOB LIST FILTER
export const UPDATE_JOB_LIST_FILTER = "UPDATE_JOB_LIST_FILTER";
export const KEYWORD_KEY = "keyword";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

// GET HOMEPAGE TRENDING JOB
export const GET_TRENDING_JOBS = "GET_TRENDING_JOBS";
export const GET_TRENDING_JOBS_SUCCEED = "GET_TRENDING_JOBS_SUCCEED";
export const GET_TRENDING_JOBS_FAILED = "GET_TRENDING_JOBS_FAILED";

// GET JOB
export const GET_JOB_KEY = "GET_JOB_KEY";
export const GET_JOB = "GET_JOB";
export const GET_JOB_SUCCEED = "GET_JOB_SUCCEED";
export const GET_JOB_FAILED = "GET_JOB_FAILED";
export const END_OF_JOB_LIST = "END_OF_JOB_LIST";

// GET JOB BOOKMARK AND APPLY STATUS
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS";
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS_SUCCEED";
export const GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED =
  "GET_JOB_BOOKMARK_AND_APPLY_STATUS_FAILED";

// BOOKMARK JOB
export const BOOKMARK_JOB = "BOOKMARK_JOB";
export const BOOKMARK_JOB_SUCCEED = "BOOKMARK_JOB_SUCCEED";
export const BOOKMARK_JOB_FAILED = "BOOKMARK_JOB_FAILED";

// UNBOOKMARK JOB
export const UNBOOKMARK_JOB = "UNBOOKMARK_JOB";
export const UNBOOKMARK_JOB_SUCCEED = "UNBOOKMARK_JOB_SUCCEED";
export const UNBOOKMARK_JOB_FAILED = "UNBOOKMARK_JOB_FAILED";

// APPLY JOB
export const APPLY_JOB = "APPLY_JOB";
export const APPLY_JOB_SUCCEED = "APPLY_JOB_SUCCEED";
export const APPLY_JOB_FAILED = "APPLY_JOB_FAILED";
export const LOGIN_REQUIRED_TO_APPLY = "LOGIN_REQUIRED_TO_APPLY";

// BULK APPLY JOB
export const APPLY_JOBS = "APPLY_JOBS";
export const APPLY_JOBS_SUCCEED = "APPLY_JOBS_SUCCEED";
export const APPLY_JOBS_FAILED = "APPLY_JOBS_FAILED";

// CANCEL JOB APPLICATION
export const CANCELLING_JOB_APPLICATION = "CANCELLING_JOB_APPLICATION";
export const CANCEL_JOB_APPLICATION_SUCCEED = "CANCEL_JOB_APPLICATION_SUCCEED";

// GET TRACKS
export const GET_TRACK = "GET_TRACK";
export const GET_TRACK_SUCCEEDED = "GET_TRACK_SUCCEEDED";
export const GET_TRACK_FAILED = "GET_TRACK_FAILED";

// GET TRACKS WITH FEATURED JOB
export const GET_TRACK_WITH_FEATURED_JOBS = "GET_TRACK_WITH_FEATURED_JOBS";
export const GET_TRACK_WITH_FEATURED_JOBS_SUCCEED =
  "GET_TRACK_WITH_FEATURED_JOBS_SUCCEED";
export const GET_TRACK_WITH_FEATURED_JOBS_FAILED =
  "GET_TRACK_WITH_FEATURED_JOBS_FAILED";

// GET TRACKS WITH FAST HIRING JOB
export const GET_TRACK_WITH_FAST_HIRING_JOBS =
  "GET_TRACK_WITH_FAST_HIRING_JOBS";
export const GET_TRACK_WITH_FAST_HIRING_JOBS_SUCCEED =
  "GET_TRACK_WITH_FAST_HIRING_JOBS_SUCCEED";
export const GET_TRACK_WITH_FAST_HIRING_JOBS_FAILED =
  "GET_TRACK_WITH_FAST_HIRING_JOBS_FAILED";

// GET STATE REGION
export const GET_STATEREGION = "GET_STATEREGION";
export const GET_STATEREGION_SUCCEEDED = "GET_STATEREGION_SUCCEEDED";
export const GET_STATEREGION_FAILED = "GET_STATEREGION_FAILED";

// FETCHING FEATURED_JOBS
export const FETCHING_FEATURED_JOBS = "FETCHING_FEATURED_JOBS";
export const FETCH_FEATURED_JOBS_SUCCEED = "FETCH_FEATURED_JOBS_SUCCEEDED";
export const FETCH_FEATURED_JOBS_FAILED = "FETCH_FEATURED_JOBS_FAILED";

// FETCHING CURATED_JOBS
export const FETCHING_CURATED_JOBS = "FETCHING_CURATED_JOBS";
export const FETCH_CURATED_JOBS_SUCCEED = "FETCH_CURATED_JOBS_SUCCEEDED";
export const FETCH_CURATED_JOBS_FAILED = "FETCH_CURATED_JOBS_FAILED";

// FETCHING FAST_HIRING_JOBS
export const FETCHING_FAST_HIRING_JOBS = "FETCHING_FAST_HIRING_JOBS";
export const FETCH_FAST_HIRING_JOBS_SUCCEED =
  "FETCH_FAST_HIRING_JOBS_SUCCEEDED";
export const FETCH_FAST_HIRING_JOBS_FAILED = "FETCH_FAST_HIRING_JOBS_FAILED";

// GET JOB COMPANY JOBS
export const GET_JOB_COMPANY_JOBS = "GET_JOB_COMPANY_JOBS";
export const GET_JOB_COMPANY_JOB_SUCCEED = "GET_JOB_COMPANY_JOB_SUCCEED";
export const GET_JOB_COMPANY_JOB_FAILED = "GET_JOB_COMPANY_JOB_FAILED";

// GET JOB TYPES
export const GET_JOB_TYPE = "GET_JOB_TYPE";
export const GET_JOB_TYPE_SUCCEEDED = "GET_JOB_TYPE_SUCCEEDED";
export const GET_JOB_TYPE_FAILED = "GET_JOB_TYPE_FAILED";

// UPDATE SELECTED JOB
export const UPDATE_SELECTED_JOB = "UPDATE_SELECTED_JOB";

// GET EXPERIENCE LEVELS
export const GET_EXPERIENCE_LEVEL = "GET_EXPERIENCE_LEVEL";
export const GET_EXPERIENCE_LEVEL_SUCCEEDED = "GET_EXPERIENCE_LEVEL_SUCCEEDED";
export const GET_EXPERIENCE_LEVEL_FAILED = "GET_EXPERIENCE_LEVEL_FAILED";

// FETCHING BOOKMARK JOB LIST
export const FETCH_JOB_BOOKMARK_LIST = "FETCH_JOB_BOOKMARK_LIST";
export const FETCH_JOB_BOOKMARK_LIST_SUCCEED =
  "FETCH_JOB_BOOKMARK_LIST_SUCCEED";
export const FETCH_JOB_BOOKMARK_LIST_FAILED = "FETCH_JOB_BOOKMARK_LIST_FAILED";
export const CLEAR_JOB_BOOKMARK_LIST = "CLEAR_JOB_BOOKMARK_LIST";

// FOR UPDATING BOOKMARKED JOB IN BOOKMARK JOB LIST
export const UPDATE_BOOKMARKED_JOB = "UPDATE_BOOKMARKED_JOB";

// FETCHING APPLIED JOB LIST
export const FETCHING_APPLIED_JOBS = "FETCHING_APPLIED_JOBS";
export const FETCH_APPLIED_JOBS_SUCCEED = "FETCH_APPLIED_JOBS_SUCCEED";
export const FETCH_APPLIED_JOBS_FAILED = "FETCHING_APPLIED_JOBS_FAILED";
export const CLEAR_APPLIED_JOBS = "CLEAR_APPLIED_JOBS";

// FETCHING PENDING JOB LIST
export const FETCHING_PENDING_JOBS = "FETCHING_PENDING_JOBS";
export const FETCH_PENDING_JOBS_SUCCEED = "FETCH_PENDING_JOBS_SUCCEED";
export const FETCH_PENDING_JOBS_FAILED = "FETCHING_PENDING_JOBS_FAILED";
export const CLEAR_PENDING_JOBS = "CLEAR_PENDING_JOBS";

// FETCHING REJECTED JOB LIST
export const FETCHING_REJECTED_JOBS = "FETCHING_REJECTED_JOBS";
export const FETCH_REJECTED_JOBS_SUCCEED = "FETCH_REJECTED_JOBS_SUCCEED";
export const FETCH_REJECTED_JOBS_FAILED = "FETCH_REJECTED_JOBS_FAILED";
export const CLEAR_REJECTED_JOBS = "CLEAR_REJECTED_JOBS";

// UPDATE JOB LIST FILTER ON LANDING PAGE
export const UPDATE_JOB_LIST_FILTER_LANDING = "UPDATE_JOB_LIST_FILTER_LANDING";

// FETCHING RECOMMENDED JOB LIST
export const FETCH_RECOMMENDED_JOBS_SUCCEED = "FETCH_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_RECOMMENDED_JOBS_FAILED = "FETCH_RECOMMENDED_JOBS_FAILED";
export const FETCHING_RECOMMENDED_JOBS = "FETCHING_RECOMMENDED_JOBS";
export const UPDATE_RECOMMENDED_JOBS = "UPDATE_RECOMMENDED_JOBS";

// FETCHING ONBOARDING RECOMMENDED JOB LIST
export const FETCHING_ONBOARDING_RECOMMENDED_JOBS =
  "FETCHING_ONBOARDING_RECOMMENDED_JOBS";
export const FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_ONBOARDING_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED =
  "FETCH_ONBOARDING_RECOMMENDED_JOBS_FAILED";

// FETCHING SIMILAR JOBS UNDER JOB PAGE
export const FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED =
  "FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_SUCCEED";
export const FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED =
  "FETCH_SIMILAR_JOBS_UNDER_JOB_PAGE_FAILED";
export const FETCHING_SIMILAR_JOBS_UNDER_JOB_PAGE =
  "FETCHING_SIMILAR_JOBS_UNDER_JOB_PAGE";

// UPDATE SIMILAR JOBS
export const UPDATE_SIMILAR_APPLICATION_JOBS =
  "UPDATE_SIMILAR_APPLICATION_JOBS";

// FETCHING SIMILAR APPLICATION JOBS
export const FETCH_SIMILAR_APPLICATION_JOBS_SUCCEED =
  "FETCH_SIMILAR_APPLICATION_JOBS_SUCCEED";
export const FETCH_SIMILAR_APPLICATION_JOBS_FAILED =
  "FETCH_SIMILAR_APPLICATION_JOBS_FAILED";
export const FETCHING_SIMILAR_APPLICATION_JOBS =
  "FETCHING_SIMILAR_APPLICATION_JOBS";

// FETCHING HOMEPAGE RECOMMENDED JOBS
export const FETCHING_HOMEPAGE_RECOMMENDED_JOBS =
  "FETCHING_HOMEPAGE_RECOMMENDED_JOBS";
export const FETCH_HOMEPAGE_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_HOMEPAGE_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_HOMEPAGE_RECOMMENDED_JOBS_FAILED =
  "FETCH_HOMEPAGE_RECOMMENDED_JOBS_FAILED";

// FETCHING HOMEPAGE TRENDING KEYWORDS
export const FETCHING_HOMEPAGE_TRENDING_KEYWORDS =
  "FETCHING_HOMEPAGE_TRENDING_KEYWORDS";
export const FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED =
  "FETCH_HOMEPAGE_TRENDING_KEYWORDS_SUCCEED";
export const FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED =
  "FETCH_HOMEPAGE_TRENDING_KEYWORDS_FAILED";

// FETCHING LAST APPLIED JOB
export const FETCHING_LAST_APPLIED_JOB = "FETCHING_LAST_APPLIED_JOB";
export const FETCH_LAST_APPLIED_JOB_SUCCEED = "FETCH_LAST_APPLIED_JOB_SUCCEED";
export const FETCH_LAST_APPLIED_JOB_FAILED = "FETCH_LAST_APPLIED_JOB_FAILED";

// FETCHING HOMEPAGE SAVED JOBS
export const FETCHING_HOMEPAGE_SAVED_JOBS = "FETCHING_HOMEPAGE_SAVED_JOBS";
export const FETCH_HOMEPAGE_SAVED_JOBS_SUCCEED =
  "FETCH_HOMEPAGE_SAVED_JOBS_SUCCEED";
export const FETCH_HOMEPAGE_SAVED_JOBS_FAILED =
  "FETCH_HOMEPAGE_SAVED_JOBS_FAILED";

// FOR JOB ALERTS
export const UPDATE_JOB_ALERT_TOGGLE = "UPDATE_JOB_ALERT_TOGGLE";

export const FETCHING_JOB_ALERTS = "FETCHING_JOB_ALERTS";
export const FETCH_JOB_ALERTS_SUCCEED = "FETCH_JOB_ALERTS_SUCCEED";
export const FETCH_JOB_ALERTS_FAILED = "FETCH_JOB_ALERTS_FAILED";

export const CREATING_JOB_ALERT = "CREATING_JOB_ALERT";
export const CREATE_JOB_ALERT_SUCCEED = "CREATE_JOB_ALERT_SUCCEED";
export const CREATE_JOB_ALERT_FAILED = "CREATE_JOB_ALERT_FAILED";
export const REACHED_MAX_JOB_ALERTS = "REACHED_MAX_JOB_ALERTS";
export const JOB_ALERT_EXISTS = "JOB_ALERT_EXISTS";

export const DELETING_JOB_ALERT = "DELETING_JOB_ALERT";
export const DELETE_JOB_ALERT_SUCCEED = "DELETE_JOB_ALERT_SUCCEED";
export const DELETE_JOB_ALERT_FAILED = "DELETE_JOB_ALERT_SUCCEED";

export const REPLACING_JOB_ALERT = "REPLACING_JOB_ALERT";
export const REPLACE_JOB_ALERT_SUCCEED = "REPLACE_JOB_ALERT_SUCCEED";
export const REPLACE_JOB_ALERT_FAILED = "REPLACE_JOB_ALERT_FAILED";

export const UPDATING_JOB_ALERT = "UPDATING_JOB_ALERT";
export const UPDATE_JOB_ALERT_SUCCEED = "UPDATE_JOB_ALERT_SUCCEED";
export const UPDATE_JOB_ALERT_FAILED = "UPDATE_JOB_ALERT_FAILED";

export const GET_JOB_ALERT = "GET_JOB_ALERT";
export const GET_JOB_ALERT_SUCCEED = "GET_JOB_ALERT_SUCCEED";
export const GET_JOB_ALERT_FAILED = "GET_JOB_ALERT_FAILED";

export const VISITOR_ADDING_JOB_ALERT = "VISITOR_ADDING_JOB_ALERT";
export const VISITOR_ADD_JOB_ALERT_SUCCEED = "VISITOR_ADD_JOB_ALERT_SUCCEED";
export const VISITOR_ADD_JOB_ALERT_FAILED = "VISITOR_ADD_JOB_ALERT_FAILED";

export const VISITOR_UNSUBSCRIBING_JOB_ALERT =
  "VISITOR_UNSUBSCRIBING_JOB_ALERT";
export const VISITOR_UNSUBSCRIBE_JOB_ALERT_SUCCEED =
  "VISITOR_UNSUBSCRIBE_JOB_ALERT_SUCCEED";
export const VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED =
  "VISITOR_UNSUBSCRIBE_JOB_ALERT_FAILED";

export const CLEAR_JOB_ALERTS = "CLEAR_JOB_ALERTS";
export const CLEAR_JOB_SEARCH_CE = "CLEAR_JOB_SEARCH_CE";

// FOR SEARCH DATA AND SUGGESTIONS
export const FETCHING_SEARCH_DATA = "FETCHING_SEARCH_DATA";
export const FETCH_SEARCH_DATA_SUCCEED = "FETCH_SEARCH_DATA_SUCCEED";
export const FETCH_SEARCH_DATA_FAILED = "FETCH_SEARCH_DATA_SUGGESTIONS_FAILED";

export const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS";

// UPDATE SELECT ALL JOB FILTER
export const UPDATE_SELECT_ALL_JOB_FILTER = "UPDATE_SELECT_ALL_JOB_FILTER";

//UNSAVE_JOB
export const UNSAVE_JOB = "UNSAVE_JOB";

//SAVE_JOB
export const SAVE_JOB = "SAVE_JOB";

// FOR ASHLEY TOGGLE DIALOG
export const UPDATE_ASHLEY_TOGGLE_DIALOG = "UPDATE_ASHLEY_TOGGLE_DIALOG";

// FOR ASHLEY RECOMMENDED JOBS
export const FETCHING_ASHLEY_RECOMMENDED_JOBS =
  "FETCHING_ASHLEY_RECOMMENDED_JOBS";
export const FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED =
  "FETCH_ASHLEY_RECOMMENDED_JOBS_SUCCEED";
export const FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED =
  "FETCH_ASHLEY_RECOMMENDED_JOBS_FAILED";
export const CLEAR_ASHLEY_RECOMMENDED_JOBS_LIST =
  "CLEAR_ASHLEY_RECOMMENDED_JOBS_LIST";

export const CLOSE_ASHLEY_ERROR_DIALOG = "CLOSE_ASHLEY_ERROR_DIALOG";

// HOMEPAGE LOGO
export const FETCH_HOMEPAGE_LOGO = "FETCH_HOMEPAGE_LOGO";

// INTERNSHIP BUTTON FILTER
export const UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS =
  "UPDATE_INTERNSHIP_FILTER_BUTTON_STATUS";
export const FETCH_TRENDING_CHIP = "FETCH_TRENDING_CHIP";

// HOMEPAGE ADS SLIDER
export const FETCH_HOMEPAGE_BANNERS = "FETCH_HOMEPAGE_BANNERS";
export const UPDATE_JOB_ALERT_EMAIL = "UPDATE_JOB_ALERT_EMAIL";

// VISITOR JOB ALERT
export const SHOW_VISITOR_JOB_ALERT_DIALOG = "SHOW_VISITOR_JOB_ALERT_DIALOG";
export const SHOW_VISITOR_JOB_ALERT_SUCCESS = "SHOW_VISITOR_JOB_ALERT_SUCCESS";

// GET APPLIED JOBS
export const GET_APPLIED_JOBS_SUCCESS = "GET_APPLIED_JOBS_SUCCESS";
export const GET_APPLIED_JOBS_FAILED = "GET_APPLIED_JOBS_FAILED";

// GET PENDING JOBS
export const GET_PENDING_JOBS_SUCCESS = "GET_PENDING_JOBS_SUCCESS";
export const GET_PENDING_JOBS_FAILED = "GET_PENDING_JOBS_FAILED";

// CANCEL JOB APPLICATION
export const CANCEL_JOB_APPLICATION_SUCCESS = "CANCEL_JOB_APPLICATION_SUCCESS";
export const CANCEL_JOB_APPLICATION_FAILED = "CANCEL_JOB_APPLICATION_FAILED";

// GET SALARY TYPE
export const GET_SALARY_TYPE_SUCCESS = "GET_SALARY_TYPE_SUCCESS";
export const GET_SALARY_TYPE_FAILED = "GET_SALARY_TYPE_FAILED";
