import Cookies from "js-cookie";
import {
  generateDeviceType,
  removeItem,
  setItem,
} from "../../helpers/data_management";
import { sendTrackingEvent } from "../../helpers/tracking_management";
import api from "../../utils/api";
import * as types from "../types/user_type";

const register =
  (params = {}) =>
  async (dispatch) => {
    const deviceType = generateDeviceType();

    const payload = {
      query: `
        mutation {
          webRegister(
            input: {name: "${params.name}", email: "${params.email}", password: "${params.password}", device_type: "${deviceType}", media_source: "${params.mediaSource}"}
          ) {
            newUser
            user {
              id
              token
            }
          }
        }      
      `,
    };

    const registerRes = await api.apiCall("/api/v2/graphql", params, payload);

    if (registerRes.status === 200) {
      const { errors } = registerRes?.data;
      const { user, newUser } = registerRes?.data?.data?.webRegister || {};
      const { token } = user || {};

      if (newUser === false) {
        return dispatch({
          type: types.SIGNUP_FAILED,
          message: "Account already exist, please login instead",
        });
      }

      if (Array.isArray(errors) && errors.length > 0) {
        return dispatch({
          type: types.SIGNUP_FAILED,
          message: errors[0].message,
        });
      }

      if (!user || !token) {
        return dispatch({
          type: types.SIGNUP_FAILED,
        });
      }

      setItem(types.USER_PROFILE, JSON.stringify(user));
      setItem(types.USER_TOKEN, token);

      Cookies.set("userId", user?.id);

      return dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: {
          user: user,
        },
      });
    }

    return dispatch({
      type: types.SIGNUP_FAILED,
    });
  };

const socialMediaLogin =
  (params = {}) =>
  (dispatch) => {
    // dispatch({ type: types.SOCIAL_LOGIN });
    const deviceType = generateDeviceType();

    const payload = {
      query: `
      mutation {
        socialMediaLogin(input: {device_type: "${deviceType}", provider: "${
        params.provider
      }", access_token: "${params.accessToken}", media_source: "${
        params.urlParams
      }", bio: "${params.referralToken ? params.referralToken : null}"}) {
        user {
          id
          name
          gender
          nationality
          resume
          email
          approved
          state
          token
        } 
        newUser
        }
      }
      `,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        const user = response?.data?.data?.socialMediaLogin?.user;
        const newUser = response?.data?.data?.socialMediaLogin?.newUser;
        const token = response?.data?.data?.socialMediaLogin?.user?.token;

        if (user === null && newUser === null) {
          return dispatch({
            type: types.SIGNUP_FAILED,
            payload: {
              noEmail: true,
            },
          });
        }

        setItem(types.USER_PROFILE, JSON.stringify(user));
        setItem(types.USER_TOKEN, token);

        if (newUser) {
          Cookies.set("userId", user?.id);

          return dispatch({
            type: types.SIGNUP_SUCCESS,
            payload: {
              user: user,
            },
          });
        }

        if (newUser === false) {
          Cookies.set("userId", user?.id);

          return dispatch({
            type: types.LOGIN_SUCCESS,
            payload: {
              user: user,
            },
          });
        }

        return dispatch({
          type: types.LOGIN_FAILED,
        });
      });
  };

const login =
  (params = {}) =>
  (dispatch) => {
    if (!params.email || !params.password) return;

    const payload = {
      query: `
        mutation {
          login(input: {email: "${params.email}", password: "${params.password}"}) {
            user {
              id
              name
              profileImageSquare
              gender
              birthDate
              nationality
              mobileNumber
              currentCompany
              shortSummary
              resume
              email
              approved
              state
              onBoardingV3Complete
              educations {
                nodes {
                  id
                  educationLevel
                  graduationYear
                  educationalInstitution
                  fieldOfStudy
                  major
                }
              }
            }
            token
          }
        }    
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { errors } = response?.data;

          if (Array.isArray(errors) && errors.length > 0) {
            return dispatch({
              type: types.LOGIN_FAILED,
              errMsg: errors[0]?.message,
            });
          }

          const { user, token } = response?.data?.data?.login;

          if (user && token) {
            setItem(types.USER_PROFILE, JSON.stringify(user));
            setItem(types.USER_TOKEN, token);

            setItem(types.CLEVERTAP_INIT, "true"); // Store CleverTap initialization status once the user logs in

            Cookies.set("userId", user?.id);

            sendTrackingEvent({
              event: "CE_login",
              method: "email",
            });

            return dispatch({
              type: types.LOGIN_SUCCESS,
              payload: { user },
            });
          }
          return dispatch({
            type: types.LOGIN_FAILED,
          });
        }
        return dispatch({
          type: types.LOGIN_FAILED,
        });
      });
  };

const logout = () => (dispatch) => {
  removeItem(types.USER_TOKEN);
  removeItem(types.USER_PROFILE);
  Cookies.remove("userId");

  sendTrackingEvent({
    event: "CE_logout",
  });

  return dispatch({ type: types.LOGOUT });
};

const getUser = () => {
  let user = {};
  try {
    user = JSON.parse(localStorage.getItem(types.USER_PROFILE));
  } catch (error) {
    //
  }

  return user;
};

// resume
const getUserResumeBlob =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `{ 
        downloadResume
      }
      `,
    };

    return api
      .apiCall("/api/job_seeker/v1/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { resume, filename } = response?.data?.data?.downloadResume;

          if (resume) {
            return dispatch({
              type: types.GET_RESUME_SUCCESS,
              resumeBlob: resume,
              resumeFilename: filename,
            });
          } else {
            return dispatch({
              type: types.GET_RESUME_FAILED,
            });
          }
        } else {
          return dispatch({
            type: types.GET_RESUME_FAILED,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_RESUME_FAILED,
        });
      });
  };

const uploadResume = (params) => async (dispatch) => {
  const payload = {
    query: `
      mutation {
        resumeUpload(input: {resume: "${params.resume}", state: "${params.state}"}) {
          message
          success
          user {
            resume
            approved
            state
          }
        }
      }    
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload
  );

  if (response?.status === 200) {
    const { errors } = response?.data || {};
    const { resumeUpload } = response?.data?.data || {};

    if (Array.isArray(errors) && errors.length > 0 && errors[0]?.message) {
      return dispatch({
        type: types.UPLOAD_RESUME_FAILED,
      });
    }

    const { success, user: _user } = resumeUpload || {};

    if (!success || !_user) {
      return dispatch({
        type: types.UPLOAD_RESUME_FAILED,
      });
    }

    let localUser = JSON.parse(localStorage.getItem(types.USER_PROFILE));
    localUser.resume = _user?.resume;
    localStorage.setItem(types.USER_PROFILE, JSON.stringify(localUser));

    return dispatch({
      type: types.UPLOAD_RESUME_SUCCESS,
      payload: {
        user: _user,
      },
    });
  }

  return dispatch({
    type: types.UPLOAD_RESUME_FAILED,
  });
};

const getUserInfo = (params) => async (dispatch) => {
  const payload = {
    query: `
      {
        me {
          id
          email
          name
          profileImageSquare
          gender
          birthDate
          nationality
          mobileNumber
          currentCompany
          shortSummary
          resume
          approved
          state
          onBoardingV3Complete
          educations {
            nodes {
              id
              educationLevel
              graduationYear
              educationalInstitution
              fieldOfStudy
              major
            }
          }
          major
        }
      }    
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload
  );
  if (response.status === 200) {
    const { me } = response.data?.data;

    const { errors } = response.data;
    if (me && !errors) {
      // replace localStorage user object to latest user object
      setItem(types.USER_PROFILE, JSON.stringify(me));

      return dispatch({
        type: types.GET_USER_INFO_SUCCESS,
        payload: {
          user: me,
        },
      });
    }
    return dispatch({
      type: types.GET_USER_INFO_FAILED,
    });
  }
  return dispatch({
    type: types.GET_USER_INFO_FAILED,
  });
};

const updateEducation = (params) => async (dispatch) => {
  const payload = {
    query: `
    mutation {
      saveEducations(
        input: {educations: [{id: "${params.id ?? ""}", educationLevel: "${
      params.educationLevel
    }", educationalInstitution: "${
      params.educationalInstitution
    }", fieldOfStudy: "${params.fieldOfStudy}", graduationYear: ${
      params.graduationYear
    }, major: "${params.major}", _destroy: ${params.destroy}}]}
      ) {
        success
        educations {
          id
          educationLevel
          educationalInstitution
          fieldOfStudy
          major
          graduationYear
          description
          createdAt
          updatedAt
        }
      }
    }    
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload
  );

  // if response is 200, we still need to chekc for errors and success flag
  if (response.status === 200) {
    const { saveEducations } = response?.data?.data || {};
    const { success, educations } = saveEducations || {};

    const { errors } = response?.data || {};

    if (errors || !success) {
      return dispatch({
        type: types.SAVE_EDUCATION_FAILED,
      });
    }

    // doing this to make sure the data structure is consistent with what's returned from `me` API
    // type of `nodes` is -> Array of objects (in other term, [{}])
    const arrayToNodes = {
      nodes: educations ?? [],
    };

    return dispatch({
      type: types.SAVE_EDUCATION_SUCCESS,
      payload: {
        latestEducations: arrayToNodes,
      },
    });
  }

  // if status is not 200, we straight away return save education failed state
  return dispatch({
    type: types.SAVE_EDUCATION_FAILED,
  });
};

const updateProfile = (params) => async (dispatch) => {
  const payload = {
    query: `
      mutation {
        saveProfile(
          input: {name: "${params.name}", gender: "${params.gender}", birthDate: "${params.birthDate}", nationality: "${params.nationality}", mobileNumber: "${params.mobileNumber}", shortSummary: "${params.shortSummary}", currentCompany: "${params.currentCompany}"}
        ) {
          success
          user {
            name
            gender
            birthDate
            nationality
            mobileNumber
            currentCompany
            shortSummary
            onBoardingV3Complete
            major
          }
        }
      }    
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload
  );

  if (response.status === 200) {
    const { user, success } = response?.data?.data?.saveProfile || {};
    const { errors } = response?.data || {};

    if (errors || success === false) {
      return dispatch({
        type: types.SAVE_BASIC_INFO_FAILED,
      });
    }

    let localUser = JSON.parse(localStorage.getItem(types.USER_PROFILE));
    localUser.name = user.name;
    localUser.gender = user.gender;
    localUser.birthDate = user.birthDate;
    localUser.nationality = user.nationality;
    localUser.mobileNumber = user.mobileNumber;
    localUser.currentCompany = user.currentCompany;
    localUser.shortSummary = user.shortSummary;
    localUser.onBoardingV3Complete = user.onBoardingV3Complete;
    localUser.major = user.major;
    localStorage.setItem(types.USER_PROFILE, JSON.stringify(localUser));

    return dispatch({
      type: types.SAVE_BASIC_INFO_SUCCESS,
      payload: {
        latestUser: { ...user },
      },
    });
  }

  return dispatch({
    type: types.SAVE_BASIC_INFO_FAILED,
  });
};

const updateProfileImage = (params) => async (dispatch) => {
  const payload = {
    query: `
      mutation {
        saveProfile(
          input: {profileImage: "${params.profileImage}"}
        ) {
          success
          user {
            profileImageSquare
          }
        }
      }    
    `,
  };

  const response = await api.apiCall(
    "/api/job_seeker/v1/graphql",
    params,
    payload
  );

  if (response.status === 200) {
    const { user, success } = response?.data?.data?.saveProfile || {};
    const { errors } = response?.data || {};

    if (errors || success === false) {
      return dispatch({
        type: types.SAVE_PROFILE_IMAGE_FAILED,
      });
    }

    let localUser = JSON.parse(localStorage.getItem(types.USER_PROFILE));
    localUser.profileImageSquare = user.profileImageSquare;
    localStorage.setItem(types.USER_PROFILE, JSON.stringify(localUser));

    return dispatch({
      type: types.SAVE_PROFILE_IMAGE_SUCCESS,
      payload: {
        latestUser: { ...user },
      },
    });
  }

  return dispatch({
    type: types.SAVE_PROFILE_IMAGE_FAILED,
  });
};

const forgotPassword = (params) => async (dispatch) => {
  const payload = {
    query: `
    mutation{
      forgotPassword(input: { email: "${params.email}"}) {
        result
      }
    }
    `,
  };

  const response = await api.apiCall("/api/v2/graphql", params, payload);

  if (response.status === 200) {
    const { result } = response?.data?.data?.forgotPassword;

    if (result === true) {
      return dispatch({
        type: types.FORGOT_PASSWORD_RESET_SUCCESS,
      });
    }
    return dispatch({
      type: types.FORGOT_PASSWORD_RESET_FAILED,
    });
  }
  return dispatch({
    type: types.FORGOT_PASSWORD_RESET_SUCCESS,
  });
};

const submitContactUsForm =
  (params = {}) =>
  async (dispatch) => {
    const payload = {
      query: `
        mutation {
          sendEnquiryEmail(input: {email: "${params.email}", message: "${params.message}", serviceInterested: []}) {
            enquiryEmailSent
          }
        }
      `,
    };

    const response = await api.apiCall(
      "/api/job_seeker/v1/graphql",
      params,
      payload
    );

    if (response.status === 200) {
      const { enquiryEmailSent } = response.data?.data?.sendEnquiryEmail;

      if (enquiryEmailSent === true) {
        return dispatch({
          type: types.SUBMIT_CONTACT_US_SUCCESS,
        });
      }
      return dispatch({
        type: types.SUBMIT_CONTACT_US_FAILED,
      });
    }
    return dispatch({
      type: types.SUBMIT_CONTACT_US_FAILED,
    });
  };

const submitEmployerContactUsForm =
  (params = {}) =>
  async (dispatch) => {
    let nameInput = params.name ? `name: "${params.name}",` : "";
    let companyNameInput = params.companyName
      ? `companyName: "${params.companyName}",`
      : "";
    let emailInput = params.email ? `email: "${params.email}",` : "";
    let phoneInput = params.phone ? `phoneNumber: "${params.phone}",` : "";
    let subjectInput = params.subject ? `subject: "${params.subject}",` : "";
    let messageInput = params.message ? `message: "${params.message}",` : "";
    let originInput = params.origin ? `origin: "${params.origin}",` : "";
    let servicesInput = params.services
      ? `serviceInterested: ${JSON.stringify(params.services)},`
      : "";

    let queryInput =
      nameInput +
      companyNameInput +
      emailInput +
      phoneInput +
      subjectInput +
      messageInput +
      originInput +
      servicesInput;

    // Removing trailing ","
    if (queryInput.charAt(queryInput.length - 1) == ",") {
      queryInput = queryInput.substring(0, queryInput.length - 1);
    }

    const payload = {
      query: `
        mutation {
          sendEnquiryEmail(
            input: {${queryInput}}
          ) {
            enquiryEmailSent
          }
        }
      `,
    };

    try {
      const result = await api.apiCall(
        "/api/job_seeker/v1/graphql",
        params,
        payload
      );

      if (result.status === 200) {
        const { enquiryEmailSent } = result.data?.data?.sendEnquiryEmail;

        if (enquiryEmailSent) {
          return dispatch({
            type: types.SUBMIT_CONTACT_US_SUCCESS,
          });
        }
      }

      return dispatch({
        type: types.SUBMIT_CONTACT_US_FAILED,
      });
    } catch (e) {
      return dispatch({
        type: types.SUBMIT_CONTACT_US_FAILED,
      });
    }
  };

const resetPassword =
  (params = {}) =>
  (dispatch) => {
    const payload = {
      secret: `
      mutation {
        resetPassword(input: {password: "${params.password}", token: "${params.token}"}) {
          result
          message
        }
      }
    `,
    };

    return api
      .apiCall("/api/v2/graphql", params, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { result, message } = response.data?.data?.resetPassword;
          if (result) {
            return dispatch({
              type: types.RESET_PASSWORD_SUCCEED,
              result: result,
              message: message,
            });
          } else {
            return dispatch({
              type: types.RESET_PASSWORD_FAILED,
              result: result,
              message: message,
            });
          }
        }
        return { type: types.RESET_PASSWORD_FAILED };
      })
      .catch((error) => {
        return { type: types.RESET_PASSWORD_FAILED };
      });
  };

export {
  forgotPassword,
  getUser,
  getUserInfo,
  getUserResumeBlob,
  login,
  logout,
  register,
  resetPassword,
  socialMediaLogin,
  submitContactUsForm,
  submitEmployerContactUsForm,
  updateEducation,
  updateProfile,
  updateProfileImage,
  uploadResume,
};
