// USER LOGGED IN INFO
export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE";

// Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

// Sign up
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

// Forgot password
export const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
export const FORGOT_PASSWORD_RESET_FAILED = "FORGOT_PASSWORD_RESET_FAILED";

// Log out
export const LOGOUT = "LOGOUT";

// Download resume
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAILED = "GET_RESUME_FAILED";

// Upload Resume
export const UPLOAD_RESUME_SUCCESS = "UPLOAD_RESUME_SUCCESS";
export const UPLOAD_RESUME_FAILED = "UPLOAD_RESUME_FAILED";

// Get user info
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

// Save Education Info
export const SAVE_EDUCATION_SUCCESS = "SAVE_EDUCATION_SUCCESS";
export const SAVE_EDUCATION_FAILED = "SAVE_EDUCATION_FAILED";

// Save Basic Info
export const SAVE_BASIC_INFO_SUCCESS = "SAVE_BASIC_INFO_SUCCESS";
export const SAVE_BASIC_INFO_FAILED = "SAVE_BASIC_INFO_FAILED";

// Save profile image
export const SAVE_PROFILE_IMAGE_SUCCESS = "SAVE_PROFILE_IMAGE_SUCCESS";
export const SAVE_PROFILE_IMAGE_FAILED = "SAVE_PROFILE_IMAGE_FAILED";

// Submit Contact Us
export const SUBMIT_CONTACT_US_SUCCESS = "SUBMIT_CONTACT_US_SUCCESS";
export const SUBMIT_CONTACT_US_FAILED = "SUBMIT_CONTACT_US_FAILED";

// Reset password
export const RESET_PASSWORD_SUCCEED = "RESET_PASSWORD_SUCCEED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// Clevertap init
export const CLEVERTAP_INIT = "CLEVERTAP_INIT";
